import React, { useEffect, useState } from "react"
import { Badge, Button, Col, Form, FormControl, InputGroup, ListGroup, Modal, Row, Spinner, Table } from "react-bootstrap"

import Pagination from "./Pagination"
import { ExportCSV } from "./ExportCSV"

import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery } from "react-responsive"

// Get order details actions
import { disintegrateOrder, getBlind, getCustomer, integrateCustomer, integrateOrder } from "../actions/apiActions"
import { deleteOrder, listOrders, updateOrder } from "../actions/orderActions"
import { listItems } from "../actions/sameActions"
import { updateUser } from "../actions/userActions"

import _groupBy from 'lodash/groupBy'

import {
  addDays,
  capitalizeFirstLettersInString,
  DecimalPrecision2,
  formatDate,
  getSideBySideBlinds,
  isValidEmail
} from "../utils/common"

const getBadge = (status) => {
  switch (status) {
    case true:
      return "success"
    case false:
      return "secondary"
    case "Pending":
      return "warning"
    case "Submited":
      return "info"
    case "Shipped":
      return "success"
    case "Completed":
      return "secondary"
    default:
      return "primary"
  }
}

const getActive = (status) => {
  switch (status) {
    case true:
      return "Active"
    case false:
      return "Inactive"
    case "Pending":
      return "Pending"
    case "Submited":
      return "Submited"
    case "Shipped":
      return "Shipped"
    case "Completed":
      return "Completed"
    default:
      return "Inactive"
  }
}

const OrderAllList = ({ companyNames }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState([])
  const [selected, setSelected] = useState({})
  const [show, setShow] = useState(false)
  const [showOrderDiscount, setShowOrderDiscount] = useState(false)
  const [orderDiscount, setOrderDiscount] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isIntegrating, setIsIntegrating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const { orders } = useSelector((store) => store.orderList)
  const [currentPage, setCurrentPage] = useState(1)
  const [message, setMessage] = useState("")
  const [messageType, setMessageType] = useState("info")
  const [messageDetail, setMessageDetail] = useState("")
  const [error, setError] = useState("")
  const [orderPrice, setOrderPrice] = useState("")
  const [totalItems, setTotalItems] = useState(0)
  const [filteredValue, setFilteredValue] = useState({
    company: "",
    email: "",
    status: "",
  })
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" })
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1440px)" })
  const isExtraLargeScreen = useMediaQuery({ query: "(min-width: 2560px)" })

  const handleClose = () => setShow(false)
  const handleCloseX = () => {
    setMessage("")
    setError("")
  }

  const handleShowOrderDiscount = () => {
    setOrderDiscount(null)

    dispatch(listItems("discount", { code: selected.coupon }))
        .then(res => {
          if (res && res.status === "success") {
            if (res.discounts && res.discounts.length) {
              setOrderDiscount(res.discounts[0])

              setShowOrderDiscount(true)
            }
          }
        })
  }

  useEffect(() => {
    dispatch(listOrders({ currentPage, filteredValue }))
  }, [
    dispatch,
    currentPage,
    filteredValue,
    filteredValue.company,
    filteredValue.email,
    filteredValue.status,
    isLoading,
  ])

  useEffect(() => {
    if (orders) {
      setState(orders.data)
      setTotalItems(orders && orders.meta ? orders.meta.total : 0)
    }
  }, [orders])

  const onChangePage = (pageNum) => {
    setCurrentPage(pageNum)
  }

  const onFilterPageClear = (e) => {
    setFilteredValue({ ...filteredValue, company: "", email: "" })
    document.querySelector("#emailSelectControl").value = ""
    document.querySelector("#companySelectControl").value = ""
  }

  function onFilterValue(e) {
    if (e.target.name === "company") {
      setFilteredValue({
        ...filteredValue,
        company: e.target.value,
        email: "",
      })
      document.querySelector("#emailSelectControl").value = ""
    } else if (e.target.name === "email") {
      setFilteredValue({
        ...filteredValue,
        email: e.target.value,
        company: "",
      })
      document.querySelector("#companySelectControl").value = ""
    } else {
      setFilteredValue({ ...filteredValue, status: e.target.value })
    }
    setCurrentPage(1)
  }

  function onRowClick(id) {
    setIsDeleting(false)
    const selected =
      orders &&
      orders.data &&
      orders.data.find((item) => item._id === id)
    setSelected(selected)
    setOrderPrice(selected && (selected.total_price ? selected.total_price : selected.order_price))
    setShow(true)
  }

  const handleDisintegrateOrder = async () => {
    try {
      setIsIntegrating(true)
      setIsLoading(true)

      const disintegrateOrderResponse = await dispatch(disintegrateOrder(selected._id))

      if (disintegrateOrderResponse.status === 200) {
        const updateOrderResponse = await dispatch(updateOrder({ ...selected, isIntegrated: false }))

        if (updateOrderResponse.status === 200) {
          setError("")
          setMessage(disintegrateOrderResponse.message)
          setTimeout(() => {
            setShow(false)
            setMessage("")
            setIsIntegrating(false)
            setIsLoading(false)
          }, 3000)
        }
        else {
          setMessage("")
          setError(updateOrderResponse.msg)
          setTimeout(() => {
            setError("")
            setIsIntegrating(false)
            setIsLoading(false)
          }, 3000)
        }
      }
      else {
        throw new Error("ERROR: " + disintegrateOrderResponse.message)
      }
    }
    catch (error) {
      console.log('error', error)
      setError("")
      setMessage(error.message)
      setMessageType("error")
      setTimeout(() => {
        setMessage("")
        setMessageType("info")
        setIsIntegrating(false)
        setIsLoading(false)
      }, 5000)
    }
  }

  const handleIntegrateOrder = async () => {
    try {
      setIsIntegrating(true)
      setIsLoading(true)

      if (!isValidEmail(selected.user.email))
        throw new Error(`${selected.user.email} is not a valid email address`)

      let customer_id

      const getCustomerResponse = await dispatch(getCustomer(selected.user.email))

      if (getCustomerResponse.status === 200)
        customer_id = getCustomerResponse.data.id.toString()
      else {
        const listItemsResponse = await dispatch(listItems("address", { user_id: selected.user._id, isActive: true, isDeleted: false }))

        let data = {
          name: capitalizeFirstLettersInString(selected.user.company),
          email: selected.user.email,
          country: listItemsResponse.status === "success" && listItemsResponse.addresses.length ? listItemsResponse.addresses[0].country : null,
          city: listItemsResponse.status === "success" && listItemsResponse.addresses.length ? listItemsResponse.addresses[0].city : null,
          postcode: listItemsResponse.status === "success" && listItemsResponse.addresses.length ? listItemsResponse.addresses[0].postal_code : null,
          number: selected.user.phone ? String(selected.user.phone).padStart(11, '0') : null,
          integration_id: selected.user._id,
          integrated_from: "Portal",
        }

        const integrateCustomerResponse = await dispatch(integrateCustomer(data))

        if (integrateCustomerResponse.status === 201) {
          customer_id = integrateCustomerResponse.data.id.toString()

          await dispatch(updateUser({ ...selected.user, isIntegrated: true }))
        }
        else {
          // setMessage("")
          // setError(integrateCustomerResponse.msg)
          // setTimeout(() => {
          //   setError("")
          //   setIsIntegrating(false)
          //   setIsLoading(false)
          // }, 3000)
          throw new Error("ERROR: " + integrateCustomerResponse.message)
        }
      }

      if (customer_id) {
        let errors = []
        let items = []

        let sum_of_sqm = 0
        let sub_total = 0

        let notes = selected.note

        if (selected.order_name) {
          if (notes)
            notes = notes + "\n / Order Name: " + selected.order_name + " / "
          else
            notes = selected.order_name
        }

        let extraNotes = ""

        const clonedOrderItem = JSON.parse(JSON.stringify(selected.orderItem))

        const sideBySideBlinds = getSideBySideBlinds(clonedOrderItem)

        const clonedOrderItemWithMotor = JSON.parse(JSON.stringify(selected.orderItem)).filter(i => i.motor !== "None")

        if (clonedOrderItemWithMotor) {
          const orderItemGroupedByRemote = _groupBy(
              clonedOrderItemWithMotor,
              (i) => i.remote
          )

          for (const [key, value] of Object.entries(orderItemGroupedByRemote)) {
            if (key !== "None")
              extraNotes += `${value.length} x ${key} (${value.reduce((v, { charger }) => v + charger, 0)} charger(s))\n`
          }
        }

        if (extraNotes) {
          if (notes) notes = notes + "\n" + extraNotes
          else notes = extraNotes
        }

        const orderPrice = selected.order_price
        const sumOfOrderItemTotal = selected.orderItem.reduce((n, { prices }) => n + Number(prices.discountedPrice), 0)

        let amount_modification = null
        if (selected.discount)
          amount_modification = selected.discount

        for (let i = 0; i < selected.orderItem.length; i++) {
          const { data, status } = await dispatch(getBlind(selected.orderItem[i].blindType === "DayAndNight" ? selected.orderItem[i].fabric.substr(0, 3) : selected.orderItem[i].fabric))

          if (status === 200) {
            const name = selected.orderItem[i].window

            let group_code = ""
            if (sideBySideBlinds) {
              if (sideBySideBlinds.length > 0) {
                if (sideBySideBlinds.filter(s => s.groups.includes(name)).length === 1) {
                  group_code = sideBySideBlinds.find(s => s.groups.includes(name)).group_code
                }
              }
            }

            let blind_type
            switch (selected.orderItem[i].blindType) {
              case "DayAndNight":
                blind_type = "DAN"
                break
              case "Vellision":
                blind_type = "V"
                break
              case "Pleated":
                blind_type = "P"
                break
              case "Venetian":
                blind_type = "VEN"
                break
              case "Vertical":
                blind_type = "VER"
                break
              default:
                blind_type = "DAN"
            }

            let frame_type
            switch (selected.orderItem[i].headrail) {
              case "Open":
                frame_type = "open"
                break
              case "WhiteCovered":
                frame_type = "covered"
                break
              case "GreyCovered":
                frame_type = "greycovered"
                break
              case "AnthraciteCovered":
                frame_type = "anthracitecovered"
                break
              case "Pelmeted":
                frame_type = "pelmetted"
                break
              case "White":
                frame_type = "white"
                break
              case "Anthracite":
                frame_type = "anthracite"
                break
              case "P-White":
                frame_type = "p_white"
                break
              case "P-Black":
                frame_type = "p_black"
                break
              case "FauxWood":
                frame_type = "faux_wood"
                break
              case "FauxWoodTape":
                frame_type = "faux_wood_tape"
                break
              case "Wood":
                frame_type = "wood"
                break
              case "WoodTape":
                frame_type = "wood_tape"
                break
              case "VER-White":
                frame_type = "ver_white"
                break
              case "VER-Grey":
                frame_type = "ver_grey"
                break
              default:
                frame_type = "open"
            }

            let tassel_type
            switch (selected.orderItem[i].tassel) {
              case "Beaded":
                tassel_type = "beaded"
                break
              case "No-Beaded":
                tassel_type = "without-beads"
                break
              default:
                tassel_type = "no-tassel"
            }

            let control_option
            switch (selected.orderItem[i].controlOption) {
              case "Wand":
                control_option = "Wand"
                break
              default:
                control_option = "Cord"
            }

            let motor_type
            switch (selected.orderItem[i].motor) {
              case "Rechargeable":
                motor_type = "rechargeable"
                break
              default:
                motor_type = null
            }

            let note = selected.orderItem[i].notes
            let extraNote = ""
            if (selected.orderItem[i].blindType !== "Pleated") {
              extraNote += `${selected.orderItem[i].fitting}`
              if (selected.orderItem[i].fitting !== "Top Fix" && selected.orderItem[i].fitting !== "Normal Fix") {
                extraNote += ` (${selected.orderItem[i].bracket} x ${selected.orderItem[i].bracketCount})`
              }
            }
            if (selected.orderItem[i].blindType !== "Pleated" && selected.orderItem[i].motor === "None") {
              if (selected.orderItem[i].controlOption === "Wand") {
                extraNote += ` - Wand: (${selected.orderItem[i].wand})`
              }
              else {
                extraNote += ` - Chain: ${selected.orderItem[i].chain} (${selected.orderItem[i].cord})`
              }
            }
            if (extraNote) {
              if (note) note = note + " / " + extraNote
              else note = extraNote
            }

            const sqm = DecimalPrecision2.round((Number(selected.orderItem[i].width) / 1000) * ((Number(selected.orderItem[i].drop) + 100) / 1000), 2)
            const total = Math.round((Number(selected.orderItem[i].prices.discountedPrice) * orderPrice / sumOfOrderItemTotal) * 100 / 120 * 100)

            sum_of_sqm += sqm
            sub_total += total

            items.push({
              blind_type,
              name,
              blind_id: data.id.toString(),
              width: selected.orderItem[i].width / 10,
              height: (selected.orderItem[i].drop / 10) + 10,
              drop: selected.orderItem[i].drop / 10,
              ...(group_code !== "") && { group_code },
              ...(selected.orderItem[i].doubleGroupCode !== "") && { double_group_code: selected.orderItem[i].doubleGroupCode },
              frame_type,
              tassel_type,
              // stick: blind_type === "DAN" ? (data['is_rstick'] ? "R" : (data['is_vstick'] ? "V" : "P")) : null,
              stick: blind_type === "DAN" ? (data['is_rstick'] ? "R" : "P") : null,
              control_side: blind_type === "P" || blind_type === "VEN" ? null : selected.orderItem[i].control.toLowerCase(),
              control_option: blind_type === "P" ? null : control_option,
              motor_type,
              delivery_fee: 0,
              total,
              sqm,
              note,
            })
          } else {
            errors.push(`blind ${selected.orderItem[i].fabric} could not be found`)
            items = []

            break
          }
        }

        if (items.length === 0)
          throw new Error("ERROR: " + errors.join(', '))

        const date = selected.createdAt1
        const shipping_date = formatDate(addDays(selected.createdAt1, 5), "yyyy-MM-dd")
        const fitting_date = null
        const fitting_val = 0
        let delivery_val = Math.round(selected.delivery_price * 100 / 120) * 100

        if (amount_modification) {
          delivery_val = delivery_val - (delivery_val * amount_modification / 100)

          sub_total = Math.round(sub_total - (sub_total * amount_modification / 100))
        }

        const tax = Math.round((sub_total + fitting_val + delivery_val) * 20 / 100)
        const total = fitting_val + delivery_val + sub_total + tax

        const due_amount = 0

        const payments = [
          {
            // date: formatDate(addDays(selected.createdAt1, 5), "yyyy-MM-dd"),
            date: selected.createdAt1,
            amount: total,
          },
        ]

        let requestBody = {
          customer_id,
          date,
          shipping_date,
          fitting_date,
          sqm: sum_of_sqm,
          amount_modification,
          fitting_val,
          delivery_val,
          sub_total,
          tax,
          total,
          due_amount,
          notes,
          items,
          payments,
          integration_id: selected._id,
          integrated_from: "Portal",
        }

        const integrateOrderResponse = await dispatch(integrateOrder(requestBody))

        if (integrateOrderResponse.status === 201) {
          const updateOrderResponse = await dispatch(updateOrder({ ...selected, isIntegrated: true }))

          if (updateOrderResponse.status === 200) {
            setError("")
            setMessage(integrateOrderResponse.message)
            setMessageDetail(integrateOrderResponse.data ? "Order: " + integrateOrderResponse.data.id : "")
            setTimeout(() => {
              setShow(false)
              setMessage("")
              setMessageDetail("")
              setIsIntegrating(false)
              setIsLoading(false)
            }, 3000)
          }
          else {
            setMessage("")
            setError(updateOrderResponse.msg)
            setTimeout(() => {
              setError("")
              setIsIntegrating(false)
              setIsLoading(false)
            }, 3000)
          }
        }
        else {
          errors.push(integrateOrderResponse.message)

          throw new Error("ERROR: " + errors.join(', '))
        }
      }
      else {
        throw new Error("ERROR: Customer not found")
      }
    }
    catch (error) {
      console.log('error', error)
      setError("")
      setMessage(error.message)
      setMessageType("error")
      setTimeout(() => {
        setMessage("")
        setMessageType("info")
        setIsIntegrating(false)
        setIsLoading(false)
      }, 5000)
    }
  }

  function handleDeleteItem() {
    if (selected.isIntegrated) {
      setMessage("")
      setError("Integrated orders cannot be deleted! Please disintegrate the order before deleting it.")
      setTimeout(() => {
        setError("")
      }, 3000)
    }
    else
      setIsDeleting(true)
  }

  const handleInput = (e) => {
    if (e.target.name === "status" && e.target.value === "Completed") {
      setSelected({
        ...selected,
        [e.target.name]: e.target.value,
        isActive: false,
      })
    } else {
      setSelected({ ...selected, [e.target.name]: e.target.value })
    }
  }

  const handleDiscount = (e) => {
    let discounted_price = (orderPrice * (100 - Number(e.target.value))) / 100
    setSelected({
      ...selected,
      discount: e.target.value,
      total_price: discounted_price.toFixed(2),
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!selected.selected_address) {
      setMessage("")
      setError("address field can not be empty!")
      setTimeout(() => {
        setError("")
      }, 3000)
    } else {
      setIsLoading(true)
      dispatch(updateOrder(selected)).then((res) => {
        if (res.status === 200) {
          setError("")
          setShow(false)
          setMessage(res.msg)
          setTimeout(() => {
            setMessage("")
            setIsLoading(false)
          }, 3000)
        }
      })
    }
  }

  const handleSubmitDelete = (e) => {
    e.preventDefault()
    setIsLoading(true)
    dispatch(deleteOrder(selected._id)).then((res) => {
      if (res.status === 200) {
        setError("")
        setShow(false)
        setMessage(res.msg)
        setIsDeleting(false)
        setTimeout(() => {
          setMessage("")
          setIsLoading(false)
        }, 3000)
      }
    })
  }

  return (
    <div style={{ maxWidth: "900px", margin: "0 auto" }}>
      <Col lg={6}>
        <InputGroup className="mb-2">
          <FormControl
            id="companySelectControl"
            type="text"
            placeholder="Select Company"
            name="company"
            list="companySelect"
            size="sm"
            onChange={onFilterValue}
          />
          <datalist id="companySelect">
            {companyNames &&
              companyNames.map((item, index) => {
                return (
                  <option key={index} value={item.company}>
                    {item.company}
                  </option>
                )
              })}
          </datalist>
          <FormControl
            id="emailSelectControl"
            type="text"
            placeholder="Select Email"
            name="email"
            list="emailSelect"
            onChange={onFilterValue}
            size="sm"
          />
          <datalist id="emailSelect">
            {companyNames &&
              companyNames.map((item, index) => {
                return (
                  <option key={index} value={item.email}>
                    {item.email}
                  </option>
                )
              })}
          </datalist>
          <InputGroup.Text
            onClick={onFilterPageClear}
            style={{ cursor: "pointer" }}
            title="Clear"
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </InputGroup.Text>
        </InputGroup>
      </Col>
      <Pagination
        // items={state}
        currentPage={currentPage}
        totalItems={totalItems}
        onChangePage={onChangePage}
      />
      <Table striped bordered hover responsive size="sm">
        <thead>
          <tr>
            <th>Company</th>
            <th>Order Date</th>
            {!isMobile && (
              <>
                <th>Order Name</th>
                <th>Items</th>
              </>
            )}
            <th>Price</th>
            <th>
              <select
                name="status"
                style={{ fontWeight: "700", border: "none" }}
                onChange={onFilterValue}
              >
                <option value="">Status</option>
                <option value="Pending">Pending</option>
                <option value="Submited">Submited</option>
                <option value="Shipped">Shipped</option>
              </select>
            </th>
          </tr>
        </thead>
        <tbody>
          {state &&
            state.map((item, index) => {
              return (
                <tr key={index} onClick={() => onRowClick(item._id)}>
                  <td>{item.user && item.user.company}</td>
                  <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                  {!isMobile && (
                    <>
                      <td>{item.order_name}</td>
                      <td>{item.orderItem.length}</td>
                    </>
                  )}
                  <td>{item.total_price ? item.total_price : item.order_price}</td>
                  <td>
                    {" "}
                    <Badge bg={getBadge(item.status)}>
                      {getActive(item.status)}
                    </Badge>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>

      <Modal
          size="md"
          show={showOrderDiscount}
          onHide={() => setShowOrderDiscount(false)}
          backdrop="static"
          keyboard={false}
          centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {orderDiscount && orderDiscount.code} - Coupon Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={4}>
              <ListGroup>
                <ListGroup.Item action>Discount Type</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{orderDiscount && orderDiscount.type}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <ListGroup>
                <ListGroup.Item action>Discount Figure</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{orderDiscount && orderDiscount.figure}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <ListGroup>
                <ListGroup.Item action>Discount Start</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{orderDiscount && formatDate(new Date(orderDiscount.startAt), "MM/dd/yyyy")}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <ListGroup>
                <ListGroup.Item action>Discount End</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{orderDiscount && formatDate(new Date(orderDiscount.endAt), "MM/dd/yyyy")}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <ListGroup>
                <ListGroup.Item action>Discount One Off</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{orderDiscount && orderDiscount.oneOff ? "Yes" : "No"}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        // size={isMobile ? "sm" : "lg"}
        {
          ...isLargeScreen && {
            size: "lg"
          }
        }
        {
          ...isExtraLargeScreen && {
            size: "xl"
          }
        }
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selected && selected.user && selected.user.company} -{" "}
            {selected && selected.order_name} Order Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Order Items</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <ListGroup variant="flush" style={{ pointerEvents: "none" }}>
                <ul style={{ listStyle: "none", paddingLeft: "0" }}>
                  {selected.orderItem &&
                    selected.orderItem.map((elem, index) => {
                      return (
                        <ListGroup.Item
                            key={index}
                            style={{
                              outline: `3px solid ${elem.blindType === "DayAndNight" && elem.headrailType === "Single" ? '#bcab79' : (elem.blindType === "DayAndNight" && elem.headrailType === "Double" ? '#292929' : (elem.blindType === "Vellision" ? '#39afd1' : (elem.blindType === "Pleated" && elem.headrailType === "Single" ? '#57ae5b' : (elem.blindType === "Pleated" && elem.headrailType === "Double" ? '#84717d' : (elem.blindType === "Venetian" ? '#f6c756' : (elem.blindType === "Vertical" ? '#d64545' : '#bcab79'))))))}`,
                              marginBottom: 10,
                              padding: '0 1rem',
                              borderTop: 0,
                              borderBottom: 0,
                              minHeight: 125,
                              height: 'fit-content'
                            }}
                        >
                          <li
                              style={{
                                textAlign: 'center',
                                color: 'white',
                                background: `${elem.blindType === "DayAndNight" && elem.headrailType === "Single" ? '#bcab79' : (elem.blindType === "DayAndNight" && elem.headrailType === "Double" ? '#292929' : (elem.blindType === "Vellision" ? '#39afd1' : (elem.blindType === "Pleated" && elem.headrailType === "Single" ? '#57ae5b' : (elem.blindType === "Pleated" && elem.headrailType === "Double" ? '#84717d' : (elem.blindType === "Venetian" ? '#f6c756' : (elem.blindType === "Vertical" ? '#d64545' : '#bcab79'))))))}`,
                                marginLeft: -17,
                                marginRight: -17,
                              }}
                          >
                            {elem.blindType ?? "DayAndNight"} {elem.headrailType === "Double" ? '(Dbl)' : ''}
                          </li>
                          <li
                              style={{
                                color: `${elem.headrailType === "Double" ? 'white': 'unset'}`,
                                background: `${elem.headrailType === "Double" ? elem.doubleGroupCode : "unset"}`,
                                marginLeft: -17,
                                marginRight: -17,
                              }}
                          >
                            <div style={{
                              marginLeft: 17,
                              marginRight: 17,
                            }}>
                              Window: {elem.window} / Fabric: {elem.fabric}
                            </div>
                          </li>
                          <li>
                            Width: {elem.width} x Drop: {elem.drop}
                          </li>
                          {elem.blindType !== "Pleated" && elem.fitting && (
                              <li>
                                Fitting: {elem.fitting} {elem.fitting !== "Top Fix" && elem.fitting !== "Normal Fix" ? `(${elem.bracket} x ${elem.bracketCount})` : ""}
                              </li>
                          )}
                          <li>
                            Headrail: {elem.headrail}
                            {elem.blindType !== "Pleated" ? (
                                <>
                                  {elem.motor === "None" ? (elem.controlOption === "Wand" ? ` - Wand: (${elem.wand})` : ` - Chain: ${elem.chain} ${elem.cord ? `(${elem.cord})` : ``}`) : ``}
                                  {elem.blindType !== "Venetian" ? ` - Control: ${elem.control}` : ``}
                                  {elem.blindType === "DayAndNight" ? ` - Tassel: ${elem.tassel}` : ""}
                                </>
                            ) : null}
                          </li>
                          {(elem.blindType === "DayAndNight" || elem.blindType === "Venetian") && (
                              <>
                                {elem.continued_blind !== "None" ? (
                                    <li>
                                      Continued Blind: {elem.continued_blind}
                                    </li>
                                ) : null}
                                {elem.motor !== "None" && (
                                    <li>
                                      Motor: {elem.motor} {elem.charger !== 0 ? `(${elem.remote} x ${elem.charger})` : ``}
                                    </li>
                                )}
                              </>
                          )}
                          {elem.notes && (
                              <li>Product Note: {elem.notes}</li>
                          )}
                        </ListGroup.Item>
                      )
                    })}
                </ul>
              </ListGroup>
            </Col>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Order Note</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>{selected.note && selected.note}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Total Price</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h5>£ {selected.total_price ? selected.total_price : selected.order_price}
                    {selected.coupon
                        ? <small>&nbsp;(Disc. Coupon: <u className="text-primary -i-cursor" style={{ cursor: 'pointer' }} onClick={handleShowOrderDiscount}>{selected.coupon}</u>)</small>
                        : ""
                    }
                  </h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Shipping Address</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <FormControl
                type="text"
                placeholder="Enter Address"
                name="selected_address"
                defaultValue={selected.selected_address}
                onChange={handleInput}
              />
            </Col>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Discount</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <InputGroup>
                <InputGroup.Text id="price_discount-sm">%</InputGroup.Text>
                <FormControl
                  type="number"
                  placeholder="Enter discount"
                  name="price_discount"
                  min="0.0"
                  max="100.0"
                  step="0.1"
                  defaultValue={
                    selected && (selected.discount ? selected.discount : 0)
                  }
                  onChange={handleDiscount}
                />
              </InputGroup>
            </Col>
            <Col sm={3}>
              <ListGroup>
                <ListGroup.Item action>Status</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <InputGroup>
                <Form.Select
                  aria-label="status"
                  id="status"
                  name="status"
                  onChange={handleInput}
                >
                  <option value={selected.status}>{selected.status}</option>
                  <option value="Pending">Pending</option>
                  <option value="Submited">Submited</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Completed">Completed</option>
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>
          {error && (
            <div className="alert alert-warning mt-2" role="alert">
              {error}
            </div>
          )}
        </Modal.Body>
        {isDeleting && (
          <Modal.Footer className="d-flex justify-content-center">
            <div>
              <Button
                size={isMobile && "sm"}
                variant="danger"
                onClick={handleSubmitDelete}
              >
                I'm sure I want to delete the item
              </Button>
            </div>
          </Modal.Footer>
        )}
        <Modal.Footer className="d-flex justify-content-between">
          <div className="d-flex justify-content-between">
            <ExportCSV content={selected} disabled={message || error || isLoading} />
            <Button
                size={isMobile && "sm"}
                variant={`${selected.isIntegrated ? `secondary` : `info`}`}
                onClick={selected.isIntegrated ? handleDisintegrateOrder : handleIntegrateOrder}
                className="ms-2"
                disabled={message || error || isLoading}
            >
              {isIntegrating && (
                  <Spinner animation="border" size="sm" variant="dark" />
              )}
              {selected.isIntegrated ? "Disintegrate" : "Integrate"}
            </Button>
          </div>
          <div>
            <Button
              size={isMobile && "sm"}
              variant="danger"
              onClick={handleDeleteItem}
              disabled={message || error || isLoading}
            >
              Delete
            </Button>
          </div>
          <div>
            <Button
              size={isMobile && "sm"}
              variant="secondary"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              size={isMobile && "sm"}
              className="ms-2"
              variant="primary"
              onClick={handleSubmit}
              disabled={message || error || isLoading}
            >
              Update
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={!!message} onHide={handleCloseX} centered>
        <Modal.Header closeButton>
          <Modal.Title>{messageType === "info" ? "Info" : "Error"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && (
            <div className={`alert alert-${messageType === "info" ? `success` : `danger`} mt-2`} role="alert">
              {message}
              {messageDetail && <><br /><span>{messageDetail}</span></>}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default OrderAllList
